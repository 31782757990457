import { IAPIResponse } from "../types/apiTypes";

export class APIClass {
  static getSearchParamsObjectFromRequest(req: Request) {
    const urlSearchParams = new URL(req.url).searchParams;
    const searchParamsObject = Object.fromEntries(urlSearchParams.entries());
    return searchParamsObject;
  }

  private getHeaders(options?: RequestInit | null, body?: BodyInit | null): HeadersInit {
    // Don't set content-type for FormData - browser will set it with boundary
    if (body instanceof FormData) {
      // @ts-ignore
      const { "Content-Type": _, ...restHeaders } = options?.headers || {};
      return restHeaders;
    }

    // Set application/json for JSON bodies
    if (typeof body === "string" || body instanceof URLSearchParams) {
      return {
        "Content-Type": "application/json",
        ...options?.headers,
      };
    }

    // Return original headers if no body or unknown type
    return options?.headers || {};
  }

  async get<T>(url: string, options?: RequestInit | undefined | null) {
    const headers = this.getHeaders(options);
    const response = await fetch(url, {
      ...options,
      headers,
    });
    return await this.handleResponse<T>(response);
  }

  async post<T>(url: string, options?: RequestInit | undefined | null) {
    const headers = this.getHeaders(options, options?.body);
    const response = await fetch(url, {
      ...options,
      method: "POST",
      headers,
    });
    return await this.handleResponse<T>(response);
  }

  async put<T>(url: string, options?: RequestInit | undefined | null) {
    const headers = this.getHeaders(options, options?.body);
    const response = await fetch(url, {
      ...options,
      method: "PUT",
      headers,
    });
    return await this.handleResponse<T>(response);
  }

  async delete<T>(url: string, options?: RequestInit | undefined | null) {
    const headers = this.getHeaders(options);
    const response = await fetch(url, {
      ...options,
      method: "DELETE",
      headers,
    });
    return await this.handleResponse<T>(response);
  }

  private handleResponse<T>(response: Response): Promise<IAPIResponse<T>> {
    const contentType = response.headers.get("Content-Type");
    //success response
    if (contentType?.includes("application/json")) {
      return response.json() as Promise<IAPIResponse<T>>;
    }

    //non-json response
    return { success: false, message: "Error" } as unknown as Promise<IAPIResponse<T>>;
  }
}

const API = new APIClass();
export default API;

export const baseApiRoutes = {
  functions: {
    events: "/api/functions/events",
  },
  equipment: {
    automaticTransferSwitches: "/api/equipment/automatic_transfer_switches",
    dcacInverter: "/api/equipment/dcac_inverters",
    disconnects: "/api/equipment/disconnects",
    evCharger: "/api/equipment/ev_chargers",
    generator: "/api/equipment/generator",
    genericLoad: "/api/equipment/generic_load",
    inverter: "/api/equipment/inverter",
    meter: "/api/equipment/meter",
    junctionBox: "/api/equipment/junction_box",
    motor: "/api/equipment/motor",
    panel: "/api/equipment/panel",
    panelSlot: "/api/equipment/panel_slot",
    transformer: "/api/equipment/transformers",
    solarArray: "/api/equipment/solar_array",
    switchboards: "/api/equipment/switchboard",
    utilityConnection: "/api/equipment/utility_connection",
    variableFrequencyDrive: "/api/equipment/variable_frequency_drive",
    conductors: "/api/equipment/conductors",
    fusedSwitch: "/api/equipment/fused_switch",
    photos: "/api/equipment/photos",
    videos: "/api/equipment/videos",
    fileRecord: "/api/equipment/file_record",
  },
};
